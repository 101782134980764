import Vue from "vue";

/*TITLE*/
document.title = "PC 30 | Porcelanosa PARTNERS";
/*PROJECT TYPE*/
Vue.prototype.$typeProject = "PARTNERS";

/*HERO*/
Vue.prototype.$title = "PC 30";
Vue.prototype.$subtitle = "¿te quedas a vivir aquí?";

/*INTRO*/
Vue.prototype.$promoter = "PC 30";
Vue.prototype.$introSubtitle = "¿te quedas a vivir aquí?";

/*GALLERY*/
Vue.prototype.$image_sittingroom = "salon-forest-maple-22x90-copia--20230126020153.jpg";
Vue.prototype.$image_kitchen = "";
Vue.prototype.$image_bath1 = "bath-1-forest-maple-22x90-rodano-caliza-33x59-deco-forest-maple-22x90_jpg--20230126020103.jpg";
Vue.prototype.$image_bath2 = "bath-2-forest-maple-22x90-rodano-caliza-33x59-deco-mosaico-rodano-_jpg--20230126020110.jpg";
Vue.prototype.$image_room = "";
Vue.prototype.$image_facade = "";

Vue.prototype.$gallery_images_sittingroom = [
  {
    src: "salon-forest-maple-22x90-copia--20230126020153.jpg",
  },
];

Vue.prototype.$gallery_images_kitchen = [
];

Vue.prototype.$gallery_images_bath1 = [
  {
    src: "bath-1-forest-maple-22x90-rodano-caliza-33x59-deco-forest-maple-22x90_jpg--20230126020103.jpg",
  },
];

Vue.prototype.$gallery_images_bath2 = [
  {
    src: "bath-2-forest-maple-22x90-rodano-caliza-33x59-deco-mosaico-rodano-_jpg--20230126020110.jpg",
  },
];

Vue.prototype.$gallery_images_room = [
];

Vue.prototype.$gallery_images_facade = [
];

/*GALLERY GRID*/
Vue.prototype.$gallery_grid_sittingroom = "6";
Vue.prototype.$gallery_grid_kitchen = "6";
Vue.prototype.$gallery_grid_bath1 = "6";
Vue.prototype.$gallery_grid_bath2 = "6";
Vue.prototype.$gallery_grid_room = "6";
Vue.prototype.$gallery_grid_facade = "6";

/*TOUR VIRTUAL*/
Vue.prototype.$360 = "";

/*3D FILM*/
Vue.prototype.$3dFilm = "";

/*CATALOG*/
Vue.prototype.$catalog = true;

/*SPACES*/
Vue.prototype.$spaces = "";

/*PROMOTION LOGO*/
Vue.prototype.$promotion_logo = true;

/*PROMOTER LOGO*/
Vue.prototype.$promoter_logo = true;

/*CONTACT*/
Vue.prototype.$promoter_address = [
  {
    icon: "mdi-map-marker",
    link: "https://maps.google.com/?q=tarragona",
    text: "Tarragona",
  },
];

Vue.prototype.$showroom = [
  {
    icon: "mdi-map-marker",
    link: "https://maps.google.com/?q=calle%20josep%20carner%201%2043007%20–%20tarragona",
    text: "Calle Josep Carner, 1 43007 – Tarragona",
  },
];

Vue.prototype.$contact = [
  {
    icon: "mdi-phone",
    link: "telf:977290949",
    text: "977290949",
  },
  {
    icon: "mdi-email",
    link: "mailto:ventas@promocionesjoseluis.com",
    text: "ventas@promocionesjoseluis.com",
  },
];
